import { faInfoCircle, faSearch, faTrash, faPencilAlt, faHistory, faMapMarkerAlt, faFileDownload, faPlus, faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Action, AddClient, AdditionalField, Address, ChangeHistories, Delete, Edit, EditClient, LangAdd, LangAvailableFeatureInfo, LangClient, LangClientVisit, LangClientVisitMustOnSpot, LangConfirm, LangDeletePermanentConfirm, LangExportAllClient, LangExportExcel, LangExportSelectedClient, LangExportSelectedClientCount, LangImportExcel, LangImportExcelClientFormat, LangNo, LangVisitHistory, LangYes, Name, Search } from '../../constant/languange'
import { axiosCompany, capitalizeFirstLetter, lang, Link, mapStateToProps, PaginationDiv, SpinnerButton, SpinnerLoadFirst } from '../../helpers/Common'
import ModalConfirmComponent from '../common/ModalConfirmComponent'
import BreadcrumbsMenu from '../../layout/breadcrumb/breadcrumbMenu'
import ClientAddClientComponent from './ClientAddClientComponent'
import { ModalApp } from '../../helpers/Input'
import ClientEditClientComponent from './ClientEditClientComponent'
import ModalAdminActivityLogsComponent from '../admin/ModalAdminActivityLogsComponent'
import ClientImportComponent from './ClientImportComponent'
import ClientImportFormatComponent from './ClientImportFormatComponent'

function ClientComponent({ redux }) {
  const [control, setControl] = useState({ showLoad: false, showModal: '' })
  const [filter, setFilter] = useState({
    page: 1,
    keyword: ''
  })
  const [dataResponse, setDataResponse] = useState({
    data: [],
    meta: {
      total: 0
    }
  })
  const [currentData, setCurrentData] = useState({})
  const [errors, setError] = useState({ fields: [], message: '' })
  const [selectedClient, setSelectedClient] = useState([])
  const [currentPageIds, setCurrentPageIds] = useState([])

  useEffect(() => {
    getListService()
    // eslint-disable-next-line
  }, [])

  const getListService = () => {
    setControl(control => ({ ...control, showLoad: true }))

    axiosCompany().get('/client-visit-clients', { params: filter })
      .then(res => {
        setDataResponse({ ...res.data })
        setCurrentPageIds(res.data.data.map(item => item.id).sort())
        setControl(control => ({ ...control, showLoad: false }))
      }).catch(e => {
        setControl(control => ({ ...control, showLoad: false }))
      })
  }

  const deleteDataService = () => {
    setControl(control => ({ ...control, showLoad: true }))

    axiosCompany().delete(`client-visit-clients/${currentData.id}`)
      .then(() => {
        setControl(control => ({ ...control, showLoad: false, showModal: '' }))
        getListService()
      }).catch(e => {
        setError(error => {
          error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
          error.message = e.response.data.message ? e.response.data.message : ''
          return { ...error }
        })
        setControl(control => ({ ...control, showLoad: false }))
      })
  }

  const handleSearch = () => {
    filter.page = 1
    getListService()
  }

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      filter.page = 1
      getListService()
    }
  }

  const handlePageChange = (page) => {
    filter.page = page
    getListService()
  }

  const handleModalAction = (payload, type = '') => {
    setCurrentData({ ...payload })
    setControl(control => ({ ...control, showModal: type }))
  }

  const handleExportExcel = (type) => {
    setControl(currControl => ({ ...currControl, onExport: true }))
    const submitFilter = {}
    if (type === 'selected') {
      submitFilter.id = selectedClient
    }
    axiosCompany().post(`/client-visit-clients/export-excel`, submitFilter, {
      responseType: 'blob'
    }).then((result) => {
      const url = window.URL.createObjectURL(new window.Blob([result.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${type === 'selected' ? 'kerjoo-export-selected-clients.xlsx' : 'kerjoo-export-all-clients.xlsx'}`)
      document.body.appendChild(link)
      link.click()
    }).finally(() => {
      setControl(currControl => ({ ...currControl, onExport: false }))
    })
  }

  const checkSelectedCurrentPage = () => {
    if (currentPageIds.every(v => selectedClient.includes(v))) {
      return true
    } else {
      return false
    }
  }

  const handleSelectAll = (e) => {
    if (selectedClient.some(a => currentPageIds.includes(a))) {
      setSelectedClient(data => {
        const newData = [...data]
        return newData.filter(item => !currentPageIds.includes(item))
      })
    } else {
      setSelectedClient(data => {
        const newData = [...data]
        const selectData = dataResponse.data.map(item => item.id)
        newData.push(...selectData)
        const uniq = [...new Set(newData)]
        return uniq
      })
    }
  }

  const handleSelectOne = (payload) => {
    setSelectedClient(data => {
      const newData = [...data]
      if (data.indexOf(payload) !== -1) {
        newData.splice(data.indexOf(payload), 1)
      } else {
        newData.push(payload)
      }
      return newData
    })
  }

  return (
    <div>
      <BreadcrumbsMenu title={LangClient}>{[LangClientVisit, LangClient]}</BreadcrumbsMenu>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col md-12'>
            <div className='card'>
              <div className='card-header'>
                {(redux.isTrial)
                  ? (
                    <div className='alert alert-info outline'>
                      <FontAwesomeIcon icon={faInfoCircle} /> {lang(LangAvailableFeatureInfo, { package: capitalizeFirstLetter(redux.features?.client_visit?.min_package) })}
                    </div>
                  )
                  : null}

                <div className='d-md-flex justify-content-between'>
                  <div className='mr-2 mb-2'>
                    <div className='input-group'>
                      <input type='search' className='form-control' placeholder={Search} value={filter.keyword} onKeyDown={handleKeydown} onChange={(e) => { setFilter({ ...filter, keyword: e.target.value }) }} style={{ maxWidth: 230 }} />
                      <div className='input-group-append' disabled={control.showLoad} id='button-addon2' onClick={handleSearch}>
                        <span className={`input-group-text ${redux.darkModeOn ? 'bg-dark border-dark' : 'bg-light text-dark'}`}>
                          {control.showLoad ? SpinnerButton : <FontAwesomeIcon icon={faSearch} />}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button onClick={() => setControl(control => ({ ...control, showModal: 'add-client' }))} type='button' className='mb-2 mr-2 btn btn-primary'>{(control.showLoad) ? SpinnerButton : <FontAwesomeIcon icon={faPlus} />} {LangAdd}</button>
                    <button onClick={() => setControl(control => ({ ...control, showModal: 'import' }))} disabled={control.showLoad} type='button' className='mb-2 mr-2 btn btn-primary'>{(control.showLoad) ? SpinnerButton : <FontAwesomeIcon icon={faFileDownload} />} {LangImportExcel}</button>
                    <div className='btn-group mb-2'>
                      <button type='button' disabled={control.onExport} className='btn btn-primary dropdown-toggle' data-toggle='dropdown' aria-expanded='false'>
                        {(control.onExport) ? SpinnerButton : <FontAwesomeIcon icon={faFileUpload} />} {LangExportExcel}
                      </button>
                      <div className={`dropdown-menu shadow ${redux.darkModeOn ? 'bg-dark' : ''}`}>
                        <button className='dropdown-item' disabled={selectedClient.length === 0} style={{ cursor: 'pointer' }} onClick={() => handleExportExcel('selected')}>{selectedClient.length !== 0 ? LangExportSelectedClientCount(selectedClient.length) : LangExportSelectedClient}</button>
                        <div className='dropdown-divider'></div>
                        <button className='dropdown-item' style={{ cursor: 'pointer' }} onClick={() => handleExportExcel('all')}>{LangExportAllClient}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                control.showLoad
                  ? SpinnerLoadFirst
                  : (
                    <div class='table-responsive'>
                      <table class='table table-striped table-bordered'>
                        <thead>
                          <tr>
                            <th scope='col' className='text-center align-middle'>
                              <div class='form-check'>
                                <input class='form-check-input position-static' type='checkbox' id='checkAllClient' checked={checkSelectedCurrentPage()} disabled={dataResponse.data.length === 0} onChange={handleSelectAll} />
                              </div>
                            </th>
                            <th scope='col' className='text-center align-middle' style={{ minWidth: 200 }}>{Name}</th>
                            <th scope='col' className='text-center align-middle' style={{ minWidth: 200 }}>{Address}</th>
                            <th scope='col' className='text-center align-middle' style={{ minWidth: 100 }}>{AdditionalField}</th>
                            <th scope='col' className='text-center align-middle' style={{ minWidth: 100 }}>Radius (Meter)</th>
                            <th scope='col' className='text-center align-middle' style={{ minWidth: 138 }}>{LangClientVisitMustOnSpot}</th>
                            <th scope='col' className='text-center align-middle' style={{ minWidth: 100 }}>{Action}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataResponse.data.map((item, i) => {
                            const address = item.address ? item.address.split('\n') : []
                            return (
                              <tr key={i}>
                                <td className='text-center'>
                                  <div class='form-check'>
                                    <input class='form-check-input position-static' type='checkbox' id={item.id} checked={selectedClient.indexOf(item.id) !== -1} onChange={() => handleSelectOne(item.id)} />
                                  </div>
                                </td>
                                <td scope='row'>{item.name}</td>
                                <td>
                                  {
                                    address.map((addr, idx) => (
                                      <span key={`addr-${i}-${idx}`}>{addr} <br /></span>
                                    ))
                                  }
                                </td>
                                <td>{item.additional_fields_count} Field</td>
                                <td>{item.radius}</td>
                                <td>{item.client_visit_must_on_spot ? LangYes : LangNo}</td>
                                <td style={{ minWidth: 180 }}>
                                  <a className={`${redux.darkModeOn ? 'btn-dark' : 'btn-light'} btn-sm mr-2 mb-2 d-inline-block`} data-tip={Edit} data-for={`tooltip-client-${item.id}`} href='#' onClick={(e) => { e.preventDefault(); handleModalAction(item, 'edit-client') }}><FontAwesomeIcon icon={faPencilAlt} color={redux.darkModeOn ? 'white' : ''} /></a>
                                  <a className={`${redux.darkModeOn ? 'btn-dark' : 'btn-light'} btn-sm mr-2 mb-2 d-inline-block`} style={{ cursor: 'pointer' }} data-tip={ChangeHistories} data-for={`tooltip-client-${item.id}`} onClick={(e) => { e.preventDefault(); handleModalAction(item, 'activity-log') }}><FontAwesomeIcon icon={faHistory} color={redux.darkModeOn ? 'white' : ''} /></a>
                                  <Link className={`${redux.darkModeOn ? 'btn-dark' : 'btn-light'} btn-sm mr-2 mb-2 d-inline-block`} data-tip={LangVisitHistory} data-for={`tooltip-client-${item.id}`} to={`/client-visit?client_id=${item.id}`}><FontAwesomeIcon icon={faMapMarkerAlt} color={redux.darkModeOn ? 'white' : ''} /></Link>
                                  <a className='btn-danger btn-sm mr-2 mb-2 d-inline-block' data-tip={Delete} data-for={`tooltip-client-${item.id}`} href='#' onClick={(e) => { e.preventDefault(); handleModalAction(item, 'delete') }}><FontAwesomeIcon icon={faTrash} /></a>
                                  <ReactTooltip place='bottom' id={`tooltip-client-${item.id}`} />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  )
              }
              <div className='card-footer'>
                <PaginationDiv data={dataResponse} onChange={handlePageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {(control.showModal === 'import') && (
        <ModalApp
          title={LangImportExcel}
          handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
          body={
            <ClientImportComponent
              handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
              handleDownloadFormat={() => setControl(control => ({ ...control, showModal: 'import-format' }))}
              handleUpdate={getListService}
            />
          }
        />
      )}

      {(control.showModal === 'import-format') && (
        <ModalApp
          title={LangImportExcelClientFormat}
          handleClose={() => setControl(control => ({ ...control, showModal: 'import' }))}
          body={
            <ClientImportFormatComponent
              handleClose={() => setControl(control => ({ ...control, showModal: 'import' }))}
              groups={redux.groups}
            />
          }
        />
      )}

      {(control.showModal === 'activity-log') && (
        <ModalApp
          title={ChangeHistories}
          size='xl'
          handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
          body={<ModalAdminActivityLogsComponent type='ClientVisitClient' id={currentData.id} />}
        />
      )}

      {(control.showModal === 'add-client') && (
        <ModalApp
          title={AddClient}
          size='xl'
          handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
          body={
            <ClientAddClientComponent
              handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
              handleUpdate={() => getListService()}
            />
          }
        />
      )}

      {(control.showModal === 'edit-client') && (
        <ModalApp
          title={EditClient}
          size='xl'
          handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
          body={
            <ClientEditClientComponent
              handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
              handleUpdate={() => getListService()}
              currentData={currentData}
            />
          }
        />
      )}

      {(control.showModal === 'delete') && (
        <ModalApp
          size='md'
          title={LangConfirm}
          handleClose={() => { setControl({ ...control, showModal: '' }); setError({ fields: [], message: '' }) }}
          body={
            <ModalConfirmComponent
              caption={LangDeletePermanentConfirm}
              btnConfirmColor='danger'
              errorMessage={errors.message}
              loader={control.showLoad}
              onSubmit={() => deleteDataService()}
              onClose={() => { setControl({ ...control, showModal: '' }); setError({ fields: [], message: '' }) }}
            />
          }
        />
      )}
    </div>

  )
}

export default connect(mapStateToProps)(ClientComponent)
