import React, { Suspense, lazy, useEffect, useState } from 'react'
import {
  Provider,
  HashRouter,
  Switch,
  store,
  SpinnerLoadPages,
  connect,
  mapStateToProps,
  swClearCache,
  axios,
  Redirect
} from './helpers/Common'
import './assets/scss/app.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import Header from './layout/header'
import Sidebar from './layout/sidebar'
import Footer from './layout/footer'
import AuthCheck from './middleware/AuthCheck'
import SessionTimeout from './middleware/SessionTimeout'
import UpdateInfo from './middleware/UpdateInfo'
import { ToastContainer, toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import Broadcast from './routes/Broadcast'
import PersonnelRoute from './routes/Personnel'
import ReimbursementRoute from './routes/Reimbursement'
import CheckPersonnelInput from './components/common/CheckPersonnelInput'
import GroupRoute from './routes/Group'
import { Route } from 'react-router'
import TourProvider from './tour/TourProvider'
import NewTourProvider from './tour/NewTourProvider'
import TourGuideAlert from './tour/components/TourGuideAlert'
import ClientComponent from './components/client/ClientComponent'
import VisitScheduleComponent from './components/visit-schedule/VisitScheduleComponent'
import ShiftChangeScheduleRecapComponent from './components/shift-change-schedule-recap/ShiftChangeScheduleRecapComponent'
import VisitSummaryComponent from './components/client-visit/VisitSummaryComponent'
import PintuKerjooRoutes from './pintu-kerjoo/routes/PintuKerjooRoutes'
import CalendarPrintComponent from './components/calendar-holiday/CalendarPrintComponent'
import OvertimeCalculationComponent from './components/overtime-calculation/OvertimeCalculationComponent'
import PositionRoute from './routes/Position'
import { LangNewVersionAvaiable } from './constant/languange'
import ExpiresModalComponent from './components/expires-info/ExpiresModalComponent'
import DefaultDataInfoComponent from './components/default-data-info/DefaultDataInfoComponent'

const Home = lazy(() => import('./routes/Home'))
const Register = lazy(() => import('./routes/Register'))
const Ott = lazy(() => import('./routes/Ott'))
const PackagePreview = lazy(() => import('./routes/PackagePreview'))
const Login = lazy(() => import('./routes/Login'))
const Verify = lazy(() => import('./routes/Verify'))
const PasswordEmail = lazy(() => import('./routes/PasswordEmail'))
const PasswordReset = lazy(() => import('./routes/PasswordReset'))
const Dashboard = lazy(() => import('./routes/Dashboard'))
const Subscribe = lazy(() => import('./routes/Subscribe'))
const AttendanceSpot = lazy(() => import('./components/attendance-spot/AttendanceSpotComponent'))
const AttendanceSummary = lazy(() => import('./components/attendance-summary/AttendanceSummaryComponent'))
const CompanySetting = lazy(() => import('./components/company/CompanySettingComponent'))
const CompanyBpjsSetting = lazy(() => import('./components/company-bpjs/CompanyBpjsComponent'))
const MobileAplication = lazy(() => import('./components/mobile-application/MobilApplicationComponent'))
const DailyAttendance = lazy(() => import('./components/attendance-daily/AttendanceDailyComponent'))
const ManageAdmin = lazy(() => import('./components/admin/AdminComponent'))
const WorkDay = lazy(() => import('./components/work-day-pattern/WorkDayPatternComponent'))
const WorkShift = lazy(() => import('./components/work-shift-pattern/WorkShiftPatternComponent'))
const ManageWorkTime = lazy(() => import('./components/work-time/WorkTimeComponent'))
const ManageWorkShift = lazy(() => import('./components/work-shift/WorkShiftComponent'))
const AttendanceReview = lazy(() => import('./components/attendance-review/AttendanceReviewComponent'))
const LeaveRequest = lazy(() => import('./components/leave-request/LeaveRequestComponent'))
const PermitRequest = lazy(() => import('./components/permit-request/PermitRequestComponent'))
const PermitRequestPrint = lazy(() => import('./components/permit-request/PermitRequestPrintComponents'))
const LeaveRequestPrint = lazy(() => import('./components/leave-request/LeaveRequestPrintComponents'))
const Developer = lazy(() => import('./components/developer/DeveloperComponent'))
const CalendarHoliday = lazy(() => import('./components/calendar-holiday/CalendarHolidayComponent'))
const PremiumFeatures = lazy(() => import('./components/premium-features/PremiumFeatures'))
const AttendanceRevision = lazy(() => import('./components/attendance-revision/AttendanceRevisionComponent'))
const OvertimeReview = lazy(() => import('./components/overtime-review/OvertimeReviewComponent'))
const OvertimeRejectionHistory = lazy(() => import('./components/overtime-review/OvertimeRejectionHistoryComponent'))
const OvertimeSchedule = lazy(() => import('./components/overtime-schedule/OvertimeScheduleComponent'))
const PermitHourReview = lazy(() => import('./components/permit-hour-review/PermitHourReviewComponent'))
const AttendanceApproval = lazy(() => import('./components/attendance-approval/AttendanceApprovalComponent'))
const DeviceApproval = lazy(() => import('./components/device-approval/DeviceApprovalComponent'))
const ClientVisit = lazy(() => import('./components/client-visit/ClientVisitComponent'))
const ClientVisitVisualization = lazy(() => import('./components/client-visit/ClientVisitVisualizationComponent'))
const Payroll = lazy(() => import('./components/payroll/PayrollComponent'))
const Paid = lazy(() => import('./components/payroll-paid/PaidComponent'))
const PayrollPrint = lazy(() => import('./components/payroll/PayrollPrintComponent'))
const PaidPrint = lazy(() => import('./components/payroll-paid/PaidPrintComponent'))
const AdminSetting = lazy(() => import('./components/admin/AdminSettingComponent'))
const AdminActivityLogs = lazy(() => import('./components/admin/AdminActivityLogsComponent'))
const SpecialLeave = lazy(() => import('./components/special-leave/SpecialLeaveComponent'))
const ApprovalRules = lazy(() => import('./components/approval-rules/ApprovalRulesComponent'))
const Reset = lazy(() => import('./components/reset/ResetComponent'))
const TimesheetActivity = lazy(() => import('./components/timesheet/TimesheetActivityComponent'))
const TimesheetProject = lazy(() => import('./components/timesheet/TimesheetProjectComponent'))
const SubscribeInvoicePrint = lazy(() => import('./components/subscribe/OrderInvoicePrintComponent'))
const AttendancePhotoProfileApproval = lazy(() => import('./components/attendance-photo-profile-approval/AttendancePhotoProfileApprovalComponent'))

const ClearCache = () => {
  const [ok, setOk] = useState(false)

  useEffect(() => {
    swClearCache(() => {
      window.alert('Clear Cache Success...')
      setOk(true)
    })
  }, [])

  if (ok) {
    return <Redirect to='/login' />
  }

  return <></>
}

const Routes = () => {
  return (
    <HashRouter>
      <Suspense fallback={SpinnerLoadPages}>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/clear-cache' component={ClearCache} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/ott' component={Ott} />
          <Route exact path='/package-preview' component={PackagePreview} />
          <Route path='/verify/:token/:id' component={Verify} />
          <Route path='/password/forgot' component={PasswordEmail} />
          <Route path='/password/reset/:token/:email' component={PasswordReset} />
          <Route path='/login' component={Login} />
          <AuthCheck>
            <NotifUpdate />
            <SessionTimeout />
            <div className='observer-tour page-wrapper compact-wrapper' id='pageWrapper'>
              <TourProvider />
              <NewTourProvider />
              <ExpiresModalComponent />
              <DefaultDataInfoComponent />
              <Header />
              <div className='page-body-wrapper sidebar-icon'>
                <Sidebar />
                <div className='page-body'>
                  <TourGuideAlert />
                  <UpdateInfo />
                  <CheckPersonnelInput />
                  <Route exact path='/leave/request/:id/print' component={LeaveRequestPrint} />
                  <Route exact path='/permit/request/:id/print' component={PermitRequestPrint} />
                  <Route exact path='/payrolls/:id/print' component={PayrollPrint} />
                  <Route exact path='/payrolls-paid/:pid/print/:id' component={PaidPrint} />
                  <Route exact path='/calendar-holiday/print' component={CalendarPrintComponent} />
                  <Route exact path='/subscribe/invoice/:id/print' component={SubscribeInvoicePrint} />
                  <PersonnelRoute />
                  <GroupRoute />
                  <PositionRoute />
                  <ReimbursementRoute />
                  <PintuKerjooRoutes />
                  <Route path='/dashboard' component={Dashboard} />
                  <Route exact path='/subscribe' component={Subscribe} />
                  <Route path='/company/setting' component={CompanySetting} />
                  <Route path='/company/BPJS' component={CompanyBpjsSetting} />
                  <Route path='/company/mobile' component={MobileAplication} />
                  <Route path='/company/overtime-calculation' component={OvertimeCalculationComponent} />
                  <Route exact path='/admin' component={ManageAdmin} />
                  <Route exact path='/admin/activity-logs' component={AdminActivityLogs} />
                  {/* <Route exact path='/admin/activity' component={AdminActivity} /> */}
                  {/* <Route path='/admin/daily-report' component={AdminDailyReport} /> */}
                  <Route exact path='/admin/setting' component={AdminSetting} />
                  <Route path='/attendance/summary' component={AttendanceSummary} />
                  <Route path='/shift-change-schedule-recap' component={ShiftChangeScheduleRecapComponent} />
                  <Route path='/attendance/review' component={AttendanceReview} />
                  <Route path='/attendance/spot' component={AttendanceSpot} />
                  <Route path='/daily/attendance' component={DailyAttendance} />
                  {/* <Route path='/manage/group' component={ManageGroup} /> */}
                  {/* <Route path='/personnel/data' component={DataPersonnel} /> */}
                  <Route path='/work/day' component={WorkDay} />
                  <Route path='/work/shift' component={WorkShift} />
                  <Route exact path='/personnel/work/time' component={ManageWorkTime} />
                  <Route path='/personnel/work/shift' component={ManageWorkShift} />
                  <Route exact path='/leave/request' component={LeaveRequest} />
                  <Route exact path='/permit/request' component={PermitRequest} />
                  <Route path='/broadcast' component={Broadcast} />
                  <Route path='/Developer' component={Developer} />
                  <Route exact path='/calendar-holiday' component={CalendarHoliday} />
                  <Route path='/premium-features' component={PremiumFeatures} />
                  <Route path='/attendance/revision' component={AttendanceRevision} />
                  <Route path='/attendance/profile-picture-approval' component={AttendancePhotoProfileApproval} />
                  <Route exact path='/overtime-review' component={OvertimeReview} />
                  <Route exact path='/overtime-review/rejection-history' component={OvertimeRejectionHistory} />
                  <Route exact path='/overtime-schedule' component={OvertimeSchedule} />
                  <Route path='/permithour-review' component={PermitHourReview} />
                  <Route path='/attendance-approval' component={AttendanceApproval} />
                  <Route path='/device-approval' component={DeviceApproval} />
                  <Route path='/client-visit' component={ClientVisit} />
                  <Route path='/client-visit-visualization' component={ClientVisitVisualization} />
                  <Route path='/visit-summary' component={VisitSummaryComponent} />
                  <Route path='/client' component={ClientComponent} />
                  <Route path='/visit-schedule' component={VisitScheduleComponent} />
                  <Route exact path='/payrolls' component={Payroll} />
                  <Route exact path='/payrolls/calculate' component={Payroll} />
                  <Route exact path='/payrolls/paid' component={Paid} />
                  <Route exact path='/company/special-leave' component={SpecialLeave} />
                  <Route exact path='/company/approval-rules' component={ApprovalRules} />
                  <Route exact path='/reset' component={Reset} />
                  <Route exact path='/timesheet/activity' component={TimesheetActivity} />
                  <Route exact path='/timesheet/project' component={TimesheetProject} />
                </div>
                <Footer />
              </div>
            </div>
          </AuthCheck>
        </Switch>
      </Suspense>
    </HashRouter>
  )
}

/**
 * ini untuk versi build harus sama dengan public/metadata.json
 */
const BUILD_NUMBER = 588

const NewBuildMsg = () => (
  <div>
    <div className='mb-2 text-success'>{LangNewVersionAvaiable}.</div>
    <button onClick={() => swClearCache(true)} type='button' className='btn btn-sm btn-success'><FontAwesomeIcon icon={faSync} /> Refresh</button>
  </div>
)

const NotifUpdate = connect(mapStateToProps)(({ redux }) => {
  const [showNotif, setShowNotif] = useState(false)

  useEffect(() => {
    if (!showNotif && redux.serviceWorkerUpdated) {
      setShowNotif(true)
    }
  }, [showNotif, redux.serviceWorkerUpdated])

  useEffect(() => {
    /**
     * ini untuk interval cek versi terbaru
     */
    const intervalId = setInterval(() => {
      axios.get('/metadata.json').then((result) => {
        if (result.data?.build > BUILD_NUMBER) {
          setShowNotif(true)
          clearInterval(intervalId)
        }
      })
    }, 1000 * 60 * 2) // 2 menit
  }, [])

  if (showNotif) {
    setShowNotif(false)

    toast(<NewBuildMsg />, {
      toastId: 'new-version-available', // tambahi ini supaya gak double
      className: 'border border-success',
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      closeButton: false
    })
  }

  return (<></>)
})

const App = () => {
  return (
    <Provider store={store}>
      <Routes />
      <ToastContainer />
    </Provider>
  )
}

export default App
