import { useState } from 'react'
import { SpinnerButton, axiosCompany, toRupiah } from '../../helpers/Common'
import { Close, LangBalance, LangExpiredIn, LangSuccessSaveData, Save } from '../../constant/languange'
import { InputDateTimePicker } from '../../helpers/Input'
import { useParams } from 'react-router-dom'

const ReimbursementEditEmployeeComponent = ({ handleClose, onAfterEdit, currentData }) => {
  const params = useParams()
  // state
  const [control, setControl] = useState({ onSubmit: false })
  const [form, setForm] = useState({
    _method: 'PUT',
    balance: currentData.balance ? toRupiah(currentData.balance) : '',
    expired_in: currentData.expired_in || ''
  })
  const [responseData, setResponseData] = useState({})

  // handler
  const handleChangeText = (e) => {
    if(e.target.name === 'balance'){
      let val = e.target.value.replace(/[^0-9]*/g,'')
      setForm(currForm => ({ ...currForm, [e.target.name]: toRupiah(val) }))
    } else {
      setForm(currForm => ({ ...currForm, [e.target.name]: e.target.value }))
    }
  }

  const handleSubmit = (e) => {
    const submitForm = {
      ...form,
      balance: form.balance.replaceAll(/\./g, "")
    }
    setControl(currControl => ({ ...currControl, onSubmit: true }))
    axiosCompany().post(`/reimbursements/${params.id}/personnels/${currentData.personnel_id}`, submitForm)
      .then(res => {
        setResponseData(curResponseData => ({ ...res.data, success: true }))
        setControl(currControl => ({ ...currControl, onSubmit: false }))
        onAfterEdit()
      })
      .catch(err => {
        setResponseData(curResponseData => err.response.data)
        setControl(currControl => ({ ...currControl, onSubmit: false }))
      })
  }

  return (
    <form>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>{LangBalance} <span className='text-danger'>*</span></label>
        <div className='col-sm-9'>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <span className={`input-group-text bg-light text-dark f-12`}>Rp</span>
            </div>
            <input type='text' name='balance' value={form.balance} onChange={handleChangeText} className={`form-control ${responseData.errors?.balance ? 'is-invalid' : ''}`} />
          </div>
          {responseData.errors?.balance && <div class='invalid-feedback d-block'>{responseData.errors?.balance}</div>}
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>{LangExpiredIn}</label>
        <div className='col-sm-9'>
          <InputDateTimePicker
            error={responseData.errors?.expired_in}
            value={form.expired_in}
            onChange={(data) => setForm(currForm => ({ ...currForm, expired_in: data }))}
          />
          {responseData.errors?.expired_in && <div class='invalid-feedback d-block'>{responseData.errors?.expired_in}</div>}
        </div>
      </div>
      {/* error message */}
      <div className='form-group row'>
        <div className='offset-3 col-sm-9'>
          {responseData.success && (<div class='alert alert-success my-2 w-100'>{LangSuccessSaveData}</div>)}
          {responseData.message && (<div className='alert alert-danger my-2 w-100'>{responseData.message}</div>)}
        </div>
      </div>
      <hr />
      <div className='form-group row'>
        <div className='offset-3 col-sm-9'>
          <div className='d-flex'>
            <button disabled={control.onSubmit} className='btn btn-primary mb-2 mr-2' type='button' onClick={handleSubmit}>{control.onSubmit && SpinnerButton} {Save}</button>
            <button className='btn btn-light mb-2' type='button' onClick={handleClose}>{Close}</button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ReimbursementEditEmployeeComponent
