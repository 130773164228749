/* eslint-disable no-sequences */
import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { axios, apiUrl, moment, cookies, formatBytes, mapStateToProps, connect, ShowImage } from './Common'
import {
  City, Province, District, Villages, TimeZone, Employe, BussinessFields, PhoneCC,
  TypeKeyword,
  NoOptions,
  PleaseWait,
  Hours,
  LangIdentity,
  LangWorkStatus,
  LangMaritalStatus,
  LangReligion,
  LangBloodType,
  LangEducationLevel,
  LangFileType,
  Gender,
  Delete
} from '../constant/languange'
import DatePicker, { registerLocale } from 'react-datepicker'
import AsyncSelect from 'react-select/async'
import 'react-datepicker/dist/react-datepicker.css'
import { addDays } from 'date-fns'
import { id } from 'date-fns/locale'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
registerLocale('id', id)

export class InputFileNew extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      preview: '',
      file: null
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.ref = React.createRef()
  }

  componentDidUpdate () {
    if (!this.props.value) {
      this.ref.current.value = ''
    }
  }

  static getDerivedStateFromProps (props) {
    if (!props.value) {
      return { preview: '', file: null }
    }
    return null
  }

  handleChange (e) {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      this.setState({ file: file })
      this.props.onChange(file)

      /**
       * if images
       */
      if (file.type.indexOf('image/') !== -1) {
        const reader = new window.FileReader()
        reader.onloadend = () => {
          this.setState({
            preview: reader.result
          })
        }

        reader.readAsDataURL(file)
      } else {
        this.setState({ preview: '' })
      }
    }
  }

  handleReset (e) {
    e.preventDefault()
    this.setState({
      preview: '',
      file: null
    }, () => {
      this.props.onChange('')
      this.ref.current.value = ''
    })
  }

  render () {
    let preview = ''
    if (this.state.file) {
      preview = (
        <div className='mt-2'>
          <div className='row'>
            <div className='col-md-6'>
              {
                (this.state.file.type.indexOf('image/') !== -1)
                  ? (<img className='img-fluid img-thumbnail' alt='' src={this.state.preview} />)
                  : (
                    <div className='alert alert-primary outline p-2'>
                      <FontAwesomeIcon icon={faFileAlt} size='lg' />
                      <p className='my-0'>
                        <p className='my-0'>{this.state.file.name}</p>
                        <p className='my-0' style={{ fontSize: 10 }}>({formatBytes(this.state.file.size)})</p>
                      </p>
                    </div>
                    )
              }
            </div>
          </div>
          <a href='#remove' onClick={this.handleReset} className='badge badge-pill badge-warning'>{Delete}</a>
        </div>
      )
    }

    return (
      <div>
        <input type='file' ref={this.ref} className={'mt-2 form-control-file' + ((this.props.error) ? ' is-invalid' : '')} onChange={this.handleChange} accept={this.props.accept} />
        {(this.props.help) ? <small className='form-text text-muted'>{this.props.help}</small> : null}
        {preview}
        {(this.props.error)
          ? <div className='invalid-feedback mt-2' role='alert'><strong>{this.props.error}</strong></div>
          : null}
      </div>
    )
  }
}

export class InputFileImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      preview: '',
      file: null
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.ref = React.createRef()
  }

  componentDidUpdate() {
    if (!this.props.value) {
      this.ref.current.value = ''
    }
  }

  static getDerivedStateFromProps(props) {
    if (!props.value) {
      return { preview: '', file: null }
    }
    return null
  }

  handleChange(e) {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      this.setState({ file: file })
      this.props.onChange(file)

      /**
       * if images
       */
      if (file.type.indexOf('image/') !== -1) {
        const reader = new window.FileReader()
        reader.onloadend = () => {
          this.setState({
            preview: reader.result
          })
        }

        reader.readAsDataURL(file)
      } else {
        this.setState({ preview: '' })
      }
    }
  }

  handleReset(e) {
    e.preventDefault()
    this.setState({
      preview: '',
      file: null
    }, () => {
      this.props.onChange('')
      this.ref.current.value = ''
    })
  }

  inputClicked(e) {
    e.preventDefault()
    this.ref.current.click();
  }

  render() {
    const inputProfilePic = {
      position: "relative",
      display: "flex",
      flexDirection: "column",
    }

    const editImgBtn = {
      border: "3px solid #FFF",
      width: "48px",
      height: "48px",
      borderRadius: "50%",
      backgroundColor: "#7366ff",
      color: "#FFF",
      position: "relative",
      alignSelf: "flex-end",
      top: "-50px",
      right: "-10px"
    }

    return (
      <div className='d-flex flex-column align-items-center'>
        <div style={inputProfilePic} >
          <ShowImage url={this.state.preview ? this.state.preview : this.props.picture_url?.m} className='img-thumbnail' alt='Personnel ID' style={{ width: '200px', marginBottom: 10 }} defaultpictureonload={1} />
          <button style={editImgBtn} onClick={(e) => this.inputClicked(e)}>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pencil-alt" class="svg-inline--fa fa-pencil-alt fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg>
          </button>
          <input type='file' ref={this.ref} className={'d-none'} onChange={this.handleChange} accept={this.props.accept} />
        </div>
        {
          this.props.error && !this.props.preview &&
          <p style={{ position: 'relative', textAlign: 'center', top: '-40px', color: 'red' }}>
            {this.props.error}
          </p>
        }
      </div>
    )
  }
}

class AsyncSelectInputComponent extends React.Component {
  render () {
    const p = this.props

    const defaultBorderColor = p.redux.darkModeOn ? '#1d1e26' : '#ddd'

    const baseStyle = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? defaultBorderColor : !this.props.error ? defaultBorderColor : 'red',
        background: p.redux.darkModeOn ? '#1d1e26' : null,
        '&:hover': {
          borderColor: state.isFocused ? defaultBorderColor : !this.props.error ? defaultBorderColor : 'red'
        }
      }),
      menu: (base, state) => ({
        ...base,
        zIndex: 2,
        background: p.redux.darkModeOn ? '#1d1e26' : null,
        color: p.redux.darkModeOn ? '#bbb' : null
      }),
      input: base => ({
        ...base,
        color: p.redux.darkModeOn ? '#bbb' : null
      }),
      option: base => ({
        ...base,
        background: p.redux.darkModeOn ? '#1d1e26' : null,
        '&:hover': {
          background: p.redux.darkModeOn ? '#262932' : null
        }
      }),
      multiValueRemove: base => ({
        ...base,
        color: p.redux.darkModeOn ? '#535353' : null
      }),
      singleValue: base => ({
        ...base,
        color: p.redux.darkModeOn ? '#bbb' : null
      })
    }
    const customStyles = {
      control: (base, state) => ({
        ...base,
        minHeight: 30,
        borderColor: state.isFocused ? defaultBorderColor : !this.props.error ? defaultBorderColor : 'red',
        background: p.redux.darkModeOn ? '#1d1e26' : null,
        '&:hover': {
          borderColor: state.isFocused ? defaultBorderColor : !this.props.error ? defaultBorderColor : 'red'
        }
      }),
      menu: (base, state) => ({
        ...base,
        background: p.redux.darkModeOn ? '#1d1e26' : null
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: 4
      }),
      clearIndicator: base => ({
        ...base,
        padding: 4
      }),
      // multiValue: base => ({
      //   ...base,
      //   backgroundColor: variables.colorPrimaryLighter
      // }),
      valueContainer: base => ({
        ...base,
        padding: '0px 6px'
      }),
      input: base => ({
        ...base,
        margin: 0,
        padding: 0,
        color: p.redux.darkModeOn ? '#bbb' : null
      }),
      option: base => ({
        ...base,
        background: p.redux.darkModeOn ? '#1d1e26' : null,
        '&:hover': {
          background: p.redux.darkModeOn ? '#262932' : null
        }
      }),
      multiValueRemove: base => ({
        ...base,
        color: p.redux.darkModeOn ? '#535353' : null
      }),
      singleValue: base => ({
        ...base,
        color: p.redux.darkModeOn ? '#bbb' : null
      })
    }

    return (
      <AsyncSelect
        isDisabled={this.props.isDisabled}
        cacheOptions
        loadOptions={this.props.loadOptions}
        defaultOptions
        isLoading={this.props.isLoading}
        isMulti={this.props.isMulti}
        placeholder={this.props.placeholder || `${TypeKeyword}...`}
        value={this.props.value}
        onChange={this.props.onChange}
        noOptionsMessage={() => (NoOptions)}
        loadingMessage={() => (PleaseWait)}
        styles={(this.props.size && this.props.size === 'sm') ? customStyles : { ...baseStyle }}
        isClearable={this.props.isClearable}
      />
    )
  }
}

export const AsyncSelectInput = connect(mapStateToProps)(AsyncSelectInputComponent)

export const ModalAction = ({ closeButton, onHide, body, show, title = '', ...props }) => {
  return (
    <Modal animation={false} show={show} onHide={onHide} {...props}>
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
    </Modal>
  )
}

export class ModalApp extends React.Component {
  render () {
    const p = this.props

    return (
      <Modal
        size={p.size || 'lg'}
        animation={false}
        show
        keyboard={false}
        onHide={p.handleClose}
        centered
        backdrop='static'
      >
        <Modal.Header closeButton={p.closeButton ?? true}>
          <Modal.Title>{p.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {p.body}
        </Modal.Body>
      </Modal>
    )
  }
}

export const ModalAppFull = (props) => {
  return (
    <Modal
      size={props.size ?? 'lg'}
      animation={false}
      show={props.show ?? true}
      keyboard={false}
      onHide={props.handleClose}
      centered
      backdrop={props.backdrop ?? true}
    >
      {props.body}
    </Modal>
  )
}

export class InputRangeDatePicker extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      startDate: '',
      endDate: '',
      limitMinDate: ''
    }

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this)
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this)
  }

  handleChangeEndDate (date) {
    this.setState({
      endDate: date
    }, () => {
      this.props.onChange({
        startDate: (this.state.startDate) ? moment(this.state.startDate).format('YYYY-MM-DD') : '',
        endDate: (this.state.endDate) ? moment(this.state.endDate).format('YYYY-MM-DD') : ''
      })
    })
  }

  handleChangeStartDate (date) {
    this.setState({
      startDate: date
    }, () => {
      this.props.onChange({
        startDate: (this.state.startDate) ? moment(this.state.startDate).format('YYYY-MM-DD') : '',
        endDate: (this.state.endDate) ? moment(this.state.endDate).format('YYYY-MM-DD') : ''
      })
    })
  }

  componentDidMount () {
    this.setState({
      startDate: (this.props.startDate) ? moment(this.props.startDate).toDate() : this.props.startDate,
      endDate: (this.props.endDate) ? moment(this.props.endDate).toDate() : this.props.endDate,
      limitMinDate: (this.props.limitMinDate) ? moment(this.props.limitMinDate).toDate() : this.props.limitMinDate
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
      this.setState({
        startDate: (this.props.startDate) ? moment(this.props.startDate).toDate() : this.props.startDate,
        endDate: (this.props.endDate) ? moment(this.props.endDate).toDate() : this.props.endDate
      })
    }
  }

  render () {
    return (
      <div className='d-flex'>
        <div className='mr-2 mr-lg-1 mr-xl-2' style={{ maxWidth: 145 }}>
          <DatePicker
            selected={this.state.startDate}
            onChange={this.handleChangeStartDate}
            className={'form-control ' + ((this.props.error) ? ' is-invalid' : '')}
            placeholderText={this.props.placeholderStartDate}
            locale={(cookies.get('lang') === 'EN') ? 'en' : 'id'}
            dateFormat='dd/MM/yyyy'
            selectsStart
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            // maxDate={this.props?.maxEndDate ? addDays(new Date(this.state.startDate), this.props.maxEndDate) : null}
            minDate={this.state.limitMinDate}
          />
        </div>
        <div className='mr-2 mr-lg-1 mr-xl-2 d-flex align-items-center'> - </div>
        <div style={{ maxWidth: 145 }}>
          <DatePicker
            selected={this.state.endDate}
            onChange={this.handleChangeEndDate}
            className={'form-control ' + ((this.props.error) ? ' is-invalid' : '')}
            placeholderText={this.props.placeholderEndDate}
            locale={(cookies.get('lang') === 'EN') ? 'en' : 'id'}
            dateFormat='dd/MM/yyyy'
            selectsEnd
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            maxDate={this.props?.maxEndDate ? addDays(new Date(this.state.startDate), this.props.maxEndDate) : null}
            minDate={this.state.startDate}
          />
        </div>
      </div>
    )
  }
}

export class InputRangeYearMonthPicker extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      startDate: '',
      endDate: ''
    }

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this)
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this)
  }

  handleChangeEndDate (date) {
    this.setState({
      endDate: date
    }, () => {
      this.props.onChange({
        startDate: (this.state.startDate) ? moment(this.state.startDate).format('YYYY-MM-DD') : '',
        endDate: (this.state.endDate) ? moment(this.state.endDate).format('YYYY-MM-DD') : ''
      })
    })
  }

  handleChangeStartDate (date) {
    this.setState({
      startDate: date
    }, () => {
      this.props.onChange({
        startDate: (this.state.startDate) ? moment(this.state.startDate).format('YYYY-MM-DD') : '',
        endDate: (this.state.endDate) ? moment(this.state.endDate).format('YYYY-MM-DD') : ''
      })
    })
  }

  componentDidMount () {
    this.setState({
      startDate: (this.props.startDate) ? moment(this.props.startDate).toDate() : this.props.startDate,
      endDate: (this.props.endDate) ? moment(this.props.endDate).toDate() : this.props.endDate
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
      this.setState({
        startDate: (this.props.startDate) ? moment(this.props.startDate).toDate() : this.props.startDate,
        endDate: (this.props.endDate) ? moment(this.props.endDate).toDate() : this.props.endDate
      })
    }
  }

  render () {
    return (
      <div className='d-flex'>
        <div className='mr-2 mr-lg-1 mr-xl-2' style={{ maxWidth: 130 }}>
          <DatePicker
            selected={this.state.startDate}
            onChange={this.handleChangeStartDate}
            className={'form-control ' + ((this.props.error) ? ' is-invalid' : '')}
            placeholderText={this.props.placeholderStartDate}
            locale={(cookies.get('lang') === 'EN') ? 'en' : 'id'}
            dateFormat='MM/yyyy'
            selectsStart
            startDate={this.state.startDate}
            // endDate={this.state.endDate}
            // maxDate={this.props?.maxEndDate ? this.state.endDate : null}
            showMonthYearPicker
          />
        </div>
        {/* <div className='mr-2 mr-lg-1 mr-xl-2 d-flex align-items-center'> - </div>
        <div style={{ maxWidth: 130 }}>
          <DatePicker
            selected={this.state.endDate}
            onChange={this.handleChangeEndDate}
            className={'form-control ' + ((this.props.error) ? ' is-invalid' : '')}
            placeholderText={this.props.placeholderEndDate}
            locale={(cookies.get('lang') === 'EN') ? 'en' : 'id'}
            dateFormat='MM/yyyy'
            selectsEnd
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            maxDate={this.props?.maxEndDate ? this.state.endDate : null}
            minDate={this.state.startDate}
            showMonthYearPicker
          />
        </div> */}
      </div>
    )
  }
}

export class InputDatePicker extends React.Component {
  render () {
    return (
      <DatePicker
        selected={(this.props.value) ? moment(this.props.value).toDate() : ''}
        onChange={date => this.props.onChange((date) ? moment(date).format('YYYY-MM-DD') : '')}
        className={'form-control' + ((this.props.error) ? ' is-invalid' : '') + ((this.props.isSm) ? ' form-control-sm ' : '')}
        placeholderText={this.props.placeholder}
        locale={(cookies.get('lang') === 'EN') ? 'en' : 'id'}
        dateFormat='dd/MM/yyyy'
        minDate={this.props.limitMinDate}
      />
    )
  }
}

export class InputDateTimePicker extends React.Component {
  render () {
    return (
      <DatePicker
        selected={(this.props.value) ? moment(this.props.value).toDate() : ''}
        onChange={date => this.props.onChange((date) ? moment(date).format('YYYY-MM-DD HH:mm:00') : '')}
        className={'form-control' + ((this.props.error) ? ' is-invalid' : '') + ((this.props.isSm) ? ' form-control-sm ' : '')}
        placeholderText={this.props.placeholder}
        locale={(cookies.get('lang') === 'EN') ? 'en' : 'id'}
        dateFormat='dd/MM/yyyy HH:mm'
        timeFormat='HH:mm'
        showTimeSelect
        timeIntervals={1}
        timeCaption={Hours}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
      />
    )
  }
}

export class InputTimePicker extends React.Component {
  render () {
    return (
      <DatePicker
        selected={(this.props.value) ? moment('2019-08-31 ' + this.props.value).toDate() : this.props.value}
        onChange={date => this.props.onChange((date) ? moment(date).format(this.props.formatReturn || 'HH:mm:00') : '')}
        className={'form-control' + ((this.props.error) ? ' is-invalid' : '') + ((this.props.isSm) ? ' form-control-sm ' : '')}
        placeholderText={this.props.placeholder}
        locale={(cookies.get('lang') === 'EN') ? 'en' : 'id'}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={this.props.timeIntervals || 60}
        timeCaption={Hours}
        dateFormat='HH:mm'
        readOnly={this.props.readOnly}
      />
    )
  }
}

export class InputMonthPicker extends React.Component {
  render () {
    return (
      <DatePicker
        selected={(this.props.value) ? moment(this.props.value).toDate() : ''}
        onChange={date => this.props.onChange((date) ? moment(date).format('YYYY-MM-DD') : '')}
        className={'form-control' + ((this.props.error) ? ' is-invalid' : '') + ((this.props.isSm) ? ' form-control-sm ' : '')}
        placeholderText={this.props.placeholder}
        locale={(cookies.get('lang') === 'EN') ? 'en' : 'id'}
        dateFormat='MMMM yyyy'
        showMonthYearPicker
      />
    )
  }
}

export class InputFile extends React.Component {
  render () {
    return (
      <>
        <Form.File
          className='filetype' id='group_image'
          placeholder={this.props.placeHolder}
          onChange={this.props.onChangeValue}
        />
      </>
    )
  }
}

export class InputAddress extends React.Component {
  render () {
    return (
      <>
        <Form.Control
          as='textarea' rows={3}
          placeholder={this.props.placeHolder}
          onChange={this.props.onChangeValue}
          value={this.props.defaultValue}
        />
      </>
    )
  }
}

export class SelectBusiness extends React.Component {
  constructor (props) {
    super(props)
    this.selected = React.createRef()
    this.state = {
      business: [],
      myBusiness: 1
    }
  }

  componentDidMount () {
    axios.get(apiUrl + '/reference/business_fields')
      .then((result) => {
        this.setState({ business: result.data })
      })
  }

    handleBusiness = (e) => {
      this.setState({ myBusiness: e.target.value }, () => {
        this.props.parentCallback(this.state.myBusiness)
      })
    }

    componentWillUnmount () {
      this.setState = (state, callback) => {

      }
    }

    render () {
      return (
        <>
          <Form.Control
            as='select' id='selectedBusiness'
            value={this.props.defaultValue || ''}
            placeholder={this.props.placeHolder} onChange={this.handleBusiness}
          >
            <option value=''>--{BussinessFields}--</option>
            {this.state.business.map((e) => {
              const option = <option key={e.name} value={e.id || ''}>{e.name}</option>
              return option
            })}
          </Form.Control>
        </>
      )
    }
}

export class SelectProvincies extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      provinces: [],
      myProvinces: ''
    }
  }

  componentDidMount () {
    axios.get(apiUrl + '/reference/provinces')
      .then((result) => {
        this.setState({ provinces: result.data })
      })
  }

  handleProvincies = (e) => {
    this.setState({ myProvinces: e.target.value }, () => {
      this.props.parentCallback(this.state.myProvinces)
    })
  }

  render () {
    return (
      <>
        <Form.Control
          ref={select => { this.select = select }}
          as='select'
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleProvincies}
          disabled={this.props.disabled ? this.props.disabled : false}
        >
          <option value=''>--{Province}--</option>
          {this.state.provinces.map((e) => {
            return <option key={e.name} value={e.id || ''}>{e.name}</option>
          })}
        </Form.Control>
      </>
    )
  }
}

export class SelectRegencies extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      provincies: this.props.provincies,
      regencies: [],
      myRegencies: '',
      onLoad: false
    }
  }

  handleRegencies = async (e) => {
    this.setState({ myRegencies: e.target.value }, () => {
      this.props.parentCallback(this.state.myRegencies)
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.provincies !== this.props.provincies) {
      this.loadData()
    }
  }

  componentDidMount () {
    this.loadData()
  }

  loadData () {
    if (!this.props.provincies) {
      this.setState({ regencies: [] })
      return true
    }

    this.setState({ onLoad: true })
    axios.get(apiUrl + `/reference/regencies_of/${this.props.provincies}`)
      .then((result) => {
        this.setState({ regencies: result.data, onLoad: false })
      })
  }

  render () {
    return (
      <>
        <Form.Control
          disabled={this.props.disabled ? this.props.disabled : this.state.onLoad}
          ref={select => { this.select = select }}
          as='select'
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleRegencies}
        >
          <option value=''>--{(this.state.onLoad) ? PleaseWait : City}--</option>
          {this.state.regencies.map((e) => {
            const option = <option key={e.name} value={e.id || ''}>{e.name}</option>
            return option
          })}
        </Form.Control>
      </>
    )
  }
}

export class SelectDistrict extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      regencies: this.props.regencies,
      district: [],
      myDistrict: '',
      onLoad: false
    }
  }

  handleRegencies = (e) => {
    this.setState({ myRegencies: e.target.value }, () => {
      this.props.parentCallback(this.state.myRegencies)
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.regencies !== this.props.regencies) {
      this.loadData()
    }
  }

  componentDidMount () {
    this.loadData()
  }

  loadData () {
    if (!this.props.regencies) {
      this.setState({ district: [] })
      return true
    }

    this.setState({ onLoad: true })
    axios.get(apiUrl + `/reference/districts_of/${this.props.regencies}`)
      .then((result) => {
        this.setState({ district: result.data, onLoad: false })
      })
  }

  render () {
    return (
      <>
        <Form.Control
          disabled={this.state.onLoad}
          ref={select => { this.select = select }}
          as='select'
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleRegencies}
        >
          <option value={'' || ''}>--{(this.state.onloadend) ? PleaseWait : District}--</option>
          {this.state.district.map((e) => {
            const option = <option key={e.name} value={e.id || ''}>{e.name}</option>
            return option
          })}
        </Form.Control>
      </>
    )
  }
}

export class SelectVillages extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      district: this.props.district,
      villages: [],
      myVillages: '',
      onLoad: false
    }
  }

  handleVillages = (e) => {
    this.setState({ myVillages: e.target.value }, () => {
      this.props.parentCallback(this.state.myVillages)
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.district !== this.props.district) {
      this.loadData()
    }
  }

  componentDidMount () {
    this.loadData()
  }

  loadData () {
    if (!this.props.district) {
      this.setState({ villages: [] })
      return true
    }

    this.setState({ onLoad: true })
    axios.get(apiUrl + `/reference/villages_of/${this.props.district}`)
      .then((result) => {
        this.setState({ villages: result.data, onLoad: false })
      })
  }

  render () {
    return (
      <>
        <Form.Control
          disabled={this.state.onLoad}
          ref={select => { this.select = select }}
          as='select'
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleVillages}
        >
          <option value={'' || ''}>--{(this.state.onLoad) ? PleaseWait : Villages}--</option>
          {this.state.villages.map((e) => {
            const option = <option key={e.name} value={e.id || ''}>{e.name}</option>
            return option
          })}
        </Form.Control>
      </>
    )
  }
}

export class SelectTime extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      timeZone: ''
    }
  }

  handleTime = (e) => {
    this.setState({ timeZone: e.target.value }, () => {
      this.props.parentCallback(this.state.timeZone)
    })
  }

  render () {
    return (
      <>
        <Form.Control
          ref={select => { this.select = select }}
          as='select'
          className={this.props.className}
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleTime}
        >
          <option key='' value=''>--{TimeZone}--</option>
          <option key='wib' value='wib'>WIB (UTC+7)</option>
          <option key='wita' value='wita'>WITA (UTC+8)</option>
          <option key='wit' value='wit'>WIT (UTC+9)</option>

        </Form.Control>
      </>
    )
  }
}

export class SelectStatus extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      status: ''
    }
  }

  handleStatus = (e) => {
    this.setState({ status: e.target.value }, () => {
      this.props.parentCallback(this.state.status)
    })
  }

  componentWillUnmount () {
    this.setState = (state, callback) => {

    }
  }

  render () {
    return (
      <>
        <Form.Control
          ref={select => { this.select = select }}
          as='select'
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleStatus}
        >
          <option key='' value=''>--Select Status--</option>
          <option key='active' value='active'>Active</option>
          <option key='pending' value='pending'>Pending</option>
          <option key='off' value='off'>Off</option>

        </Form.Control>
      </>
    )
  }
}

export class SelectEmploye extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      timeZone: ''
    }
  }

  handleTime = (e) => {
    this.setState({ timeZone: e.target.value }, () => {
      this.props.parentCallback(this.state.timeZone)
    })
  }

  componentWillUnmount () {
    this.setState = (state, callback) => {

    }
  }

  render () {
    return (
      <>
        <Form.Control
          ref={select => { this.select = select }}
          as='select'
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleTime}
        >
          <option value=''>--{Employe}--</option>
          <option value={1, 2}>2-5</option>
          <option value={3}>6-10</option>
          <option value={4}>11-25</option>
          <option value={5}>26-50</option>
          <option value={6}>More than 50</option>
        </Form.Control>
      </>
    )
  }
}

export class SelectCC extends React.Component {
  render () {
    return (
      <>
        <Form.Control
          className={this.props.className} as='select'
          value={this.props.value}
          onChange={this.props.onChange}
        >
          <option value=''>---{PhoneCC}---</option>
          <option value='62'> +62 Indonesia </option>
        </Form.Control>
      </>
    )
  }
}

export class SelectId extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pid: [],
      myPid: 1,
      lang: cookies.get('lang').toLowerCase()
    }
  }

  componentDidMount () {
    const params = { lang: this.state.lang }

    axios.get(apiUrl + '/reference/personnel_identities', { params: params })
      .then((result) => {
        this.setState({ pid: result.data.filter(x => x.type === 1) })
      })
  }

    handleId = (e) => {
      this.setState({ myPid: e.target.value }, () => {
        this.props.parentCallback(this.state.myPid)
      })
    }

    componentWillUnmount () {
      this.setState = (state, callback) => {

      }
    }

    render () {
      return (
        <>
          <Form.Control
            ref={select => { this.select = select }}
            as='select'
            className={this.props.className}
            value={this.props.defaultValue || ''}
            placeholder={this.props.placeHolder} onChange={this.handleId}
          >
            <option value=''>--{LangIdentity}--</option>
            {this.state.pid.map((e) => {
              const option = <option key={e.name} value={e.id || ''}>{e.name}</option>
              return option
            })}
          </Form.Control>
        </>
      )
    }
}

export class SelectWorkStatus extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      data: [],
      selected: ''
    }
  }

  componentDidMount () {
    axios.get(apiUrl + '/reference/working_statuses')
      .then((result) => {
        this.setState({ data: result.data })
      })
  }

    handleValue = (e) => {
      this.setState({ selected: e.target.value }, () => {
        this.props.parentCallback(this.state.selected)
      })
    }

    componentWillUnmount () {
      this.setState = (state, callback) => {

      }
    }

    render () {
      return (
        <>
          <Form.Control
            ref={select => { this.select = select }}
            as='select'
            className={this.props.className}
            value={this.props.defaultValue || ''}
            placeholder={this.props.placeHolder} onChange={this.handleValue}
          >
            <option value=''>--{LangWorkStatus}--</option>
            {this.state.data.map((e) => {
              const option = <option key={e.id} value={e.id || ''}>{cookies.get('lang') === 'ID' ? e.name : e.name_en}</option>
              return option
            })}
          </Form.Control>
        </>
      )
    }
}

export class SelectMaritalStatus extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      selected: ''
    }
  }

  componentDidMount() {
    axios.get(apiUrl + '/reference/marital-status')
      .then((result) => {
        this.setState({ data: result.data })
      })
  }

  handleValue = (e) => {
    this.setState({ selected: e.target.value }, () => {
      this.props.parentCallback(this.state.selected)
    })
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {

    }
  }

  render() {
    return (
      <>
        <Form.Control
          ref={select => { this.select = select }}
          as='select'
          className={this.props.className}
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleValue}
        >
          <option value=''>--{LangMaritalStatus}--</option>
          {this.state.data.map((e) => {
            const option = <option key={e.id} value={e.id || ''}>{cookies.get('lang') === 'ID' ? e.name : e.name_en}</option>
            return option
          })}
        </Form.Control>
      </>
    )
  }
}

export class SelectReligion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      selected: ''
    }
  }

  componentDidMount() {
    axios.get(apiUrl + '/reference/religions')
      .then((result) => {
        this.setState({ data: result.data })
      })
  }

  handleValue = (e) => {
    this.setState({ selected: e.target.value }, () => {
      this.props.parentCallback(this.state.selected)
    })
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {

    }
  }

  render() {
    return (
      <>
        <Form.Control
          ref={select => { this.select = select }}
          as='select'
          className={this.props.className}
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleValue}
        >
          <option value=''>--{LangReligion}--</option>
          {this.state.data.map((e) => {
            const option = <option key={e.id} value={e.id || ''}>{cookies.get('lang') === 'ID' ? e.name : e.name_en}</option>
            return option
          })}
        </Form.Control>
      </>
    )
  }
}

export class SelectBloodType extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      selected: ''
    }
  }

  componentDidMount() {
    axios.get(apiUrl + '/reference/blood-groups')
      .then((result) => {
        this.setState({ data: result.data })
      })
  }

  handleValue = (e) => {
    this.setState({ selected: e.target.value }, () => {
      this.props.parentCallback(this.state.selected)
    })
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {

    }
  }

  render() {
    return (
      <>
        <Form.Control
          ref={select => { this.select = select }}
          as='select'
          className={this.props.className}
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleValue}
        >
          <option value=''>--{LangBloodType}--</option>
          {this.state.data.map((e) => {
            const option = <option key={e.id} value={e.id || ''}>{e.name}</option>
            return option
          })}
        </Form.Control>
      </>
    )
  }
}

export class SelectRecentEducation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      selected: ''
    }
  }

  componentDidMount() {
    axios.get(apiUrl + '/reference/education-levels')
      .then((result) => {
        this.setState({ data: result.data })
      })
  }

  handleValue = (e) => {
    this.setState({ selected: e.target.value }, () => {
      this.props.parentCallback(this.state.selected)
    })
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {

    }
  }

  render() {
    return (
      <>
        <Form.Control
          ref={select => { this.select = select }}
          as='select'
          className={this.props.className}
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleValue}
        >
          <option value=''>--{LangEducationLevel}--</option>
          {this.state.data.map((e) => {
            const option = <option key={e.id} value={e.id || ''}>{cookies.get('lang') === 'ID' ? e.name : e.name_en}</option>
            return option
          })}
        </Form.Control>
      </>
    )
  }
}

export class SelectEmployeeFile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      selected: ''
    }
  }

  componentDidMount() {
    axios.get(apiUrl + '/reference/personnel_identities')
      .then((result) => {
        this.setState({ data: result.data })
      })
  }

  handleValue = (e) => {
    this.setState({ selected: e.target.value }, () => {
      this.props.parentCallback(this.props.name, this.props.index, this.state.selected)
    })
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {

    }
  }

  render() {
    return (
      <>
        <Form.Control
          ref={select => { this.select = select }}
          as='select'
          name={this.props.name}
          className={this.props.className}
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleValue}
        >
          <option value=''>--{LangFileType}--</option>
          {this.state.data.map((e) => {
            const option = <option key={e.id} value={e.id || ''}>{e.name}</option>
            return option
          })}
        </Form.Control>
      </>
    )
  }
}

export class SelectGender extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [
        {
          id: 'male',
          name: 'Laki-Laki',
          name_en: 'Male'
        }, {
          id: 'female',
          name: 'Perempuan',
          name_en: 'Female'
        }
      ],
      selected: ''
    }
  }

  handleValue = (e) => {
    this.setState({ selected: e.target.value }, () => {
      this.props.parentCallback(this.state.selected)
    })
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {

    }
  }

  render() {
    return (
      <>
        <Form.Control
          ref={select => { this.select = select }}
          as='select'
          className={this.props.className}
          value={this.props.defaultValue || ''}
          placeholder={this.props.placeHolder} onChange={this.handleValue}
        >
          <option value=''>--{Gender}--</option>
          {this.state.data.map((e) => {
            const option = <option key={e.id} value={e.id || ''}>{cookies.get('lang') === 'ID' ? e.name : e.name_en}</option>
            return option
          })}
        </Form.Control>
      </>
    )
  }
}

export { Form, Modal }

export const InputColorPicker = (props) => {
  return (
    <>
      <input 
        type='color' 
        list='presetColors' 
        id={props.id ?? ''}
        name={props.name ?? ''} 
        value={props.value ?? ''} 
        onChange={props.onChange ?? null} 
        className={props.className ?? 'form-control p-1'} 
        disabled={props.disabled ?? false}
        style={{ width: '100px' }} />
      <datalist id='presetColors'>
        <option>#010101</option>
        <option>#FFDA11</option>
        <option>#1F8DF2</option>
        <option>#22BB4F</option>
        <option>#616264</option>
        <option>#9A12AF</option>
        <option>#F47617</option>
        <option>#FB4A9B</option>
        <option>#B48968</option>
        <option>#E9372A</option>
        <option>#62CADE</option>
        <option>#A1A9FE</option>
        <option>#4A261A</option>
        <option>#013A20</option>
        <option>#05445E</option>
        <option>#FFE5C5</option>
        <option>#FFFAE0</option>
        <option>#F1B420</option>
        <option>#7B4360</option>
        <option>#E88D72</option>
      </datalist>
    </>
  )
}
